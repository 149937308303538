import { createSlice, current } from '@reduxjs/toolkit'
import { removeProductFromCustomizer as removeProductFromCustomizerTool } from '@/utils/handlers/customizeProductsUtils';
import { sortTicket } from '@/utils/handlers/ticketUtils';


const initialState = {
  /* CUSTOMIZING */
  productName: null,
  products2customize: [],
  /* KITCHEN */
  ordered: null,
  // socket
  pending2KitchenValue: 0,
  /* TICKET */
  ticket: null,
  ticketFetching: false,
  ticketError: null,
  sending2Ticket: false,
  removingFromTicket: false,
  sending2Kitchen: false,
}


export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    addProduct2Customize: (state, { payload }) => {
      const { products, idPriceOfProductBySize, notificationCode=null } = payload;
      const newProducts2Customize = [];
      let productName = null;
      products.forEach(product => {
        productName = {
          spanishName: product.spanishName,
          englishName: product.englishName,
        };

        const extras = (product.extras && product.extras.length > 0)  ? product.extras : [];
        const sides = (product.sides && product.sides.length > 0)  ? product.sides: [];
        const sidesId = product.sidesId ? product.sidesId : '';
        const extrasId = product.extrasId ? product.extrasId : '';
        const questions = product.questions ? product.questions : {};

        const obj2add = {
          idOrder: product.idOrder || null,
          productId: product.productId,
          idPriceBySize: idPriceOfProductBySize,
          minSides2Choose: product.minSides2Choose,
          maxSides2Choose: product.maxSides2Choose,
          minExtras2Choose: product.minExtras2Choose,
          maxExtras2Choose: product.maxExtras2Choose,
          questionAnswers:  product.questionAnswers,
          prices: product.prices,
          extras,
          extrasId,
          sides,
          sidesId,
          questions,
        }
        newProducts2Customize.push(obj2add);
      });


      state.productName = productName;
      state.products2customize = [ ...newProducts2Customize ];
    },
    removeProductFromCustomizer: (state, {payload}) => {
      const indexity = payload;
      const { products2customize } = state;
      const newProduct2Customize = [];

      products2customize.forEach((product, index) => {
        if(index!==indexity){
          newProduct2Customize.push(product);
        }
      })
      state.products2customize = [...newProduct2Customize]
    },
    resetProduct2Customize: (state) => {
      state.productName = '';
      state.products2customize = [];
    },
    setCustomizeOption: (state, { payload }) => {
      const { index:indexito, key, value } = payload;
      const { products2customize } = state;
      const newProducts2Customize = [...products2customize];
      // console.log(current(state))
      const indice = indexito;
      const currentProduct = products2customize[indice];

      let newValue = value;
      if(key === 'questions'){
        const { idQuestion, idAnswer } = value;
        currentProduct.questions[idQuestion] = idAnswer;
        newValue = currentProduct.questions;
      }
      currentProduct[key] = newValue;
      newProducts2Customize[indice] = {
        ...newProducts2Customize[indice],
        ...currentProduct
      }
      state.products2customize = [...newProducts2Customize ];      
    },
     /* GET MY TICKET */
    getMyTicket: (state) => {
      state.ticketFetching = true;
      state.ticketError = null;
    },
    getMyTicketError: (state, { payload }) => {
      const { cod } = payload;
      state.ticketFetching = false;
      state.ticketError = cod;
    },
    getMyTicketSuccess: (state, { payload }) => {
      const { ticket, ordered } = sortTicket(payload);
      const ticketjson = JSON.stringify(ticket);
      const orderedjson = JSON.stringify(ordered);
      const lastTicketjson = JSON.stringify(state.ticket);
      const lastOrderedjson = JSON.stringify(state.ordered);
      const updateTicket = ticketjson !== lastTicketjson;
      const updateOrdered = orderedjson !== lastOrderedjson;

      if (updateTicket || updateOrdered) {
        state.ordered=ordered;
        state.ticket=ticket;
        state.ticketFetching=false;
        state.ticketError=null;
      }
    },
    ticketReset: (state) => {
      state = {
        ...state,
        ...initialState,
      }
    },
    /* SEND TO TICKET */
    initSocket: (state, { payload }) => {},
    setTicketProducts: (state, { payload }) => {
      const lastTicketjson = JSON.stringify(state.ticket);
      const ticketJson = JSON.stringify(payload);

      if (lastTicketjson !== ticketJson){
        state.ticket = payload;
      }
    },
    setPending2KitchenValue: (state, {payload}) => {
      state.pending2KitchenValue = payload;
    },
    send2Ticket: (state, { payload }) => {
      // const { showNotification } = payload;
      // if(showNotification){
      //   createNotification(kindToast.sendTicketProcess);
      //   changeNotification (kindToast.sendTicketProcess, notificationsKeys.productTicketSent);
      // }
      // state.sending2Ticket = true;
    },
    send2TicketCompleted: (state, { payload }) => {
      const { customizeProdIndex } = payload;
      // changeNotification(kindToast.sendTicketProcess, notificationsKeys.addedProductOK);
      if(customizeProdIndex > -1){
        const { products2customize } = state;
        const newProduct2Customize = removeProductFromCustomizerTool(customizeProdIndex, products2customize);
        state.products2customize = [ ...newProduct2Customize];
        state.sending2Ticket = false;
      } else {
        state.sending2Ticket = false;
      }
    },
    send2TicketFailure: (state, { payload }) => {
      const { currentLanguage, appTexts } = payload;
      // changeNotification (kindToast.sendKitchenProcess, notificationsKeys.sendingKitchenKO,  {
      //   currentLanguage,
      //   appTexts,
      // });
      state.sending2Kitchen = false;
    },
    /* REMOVE FROM TICKET */
    removeFromTicket: (state, { payload }) => {
      const { arrayIdOrder } = payload; // sgas fijo
      state.removingFromTicket = true;
    },
    removeFromTicketCompleted: (state) => {
      state.removingFromTicket = false;
    },
    removeFromTicketFailure: (state, {payload}) => {
      const { errorId } = payload; //sgaas
      state.removingFromTicket = false;
    },
    /* SEND TO KITCHEN */
    sendToKitchenAsk: (state, {payload}) => {
      const { arrayIdOrder, all = 0 } = payload
      // createNotification(kindToast.sendKitchenProcess);
      if(!all){
        // changeNotification (kindToast.sendKitchenProcess, notificationsKeys.sendingProduct2Kitchen,{arrayIdOrder});
      } else {
        // changeNotification (kindToast.sendKitchenProcess, notificationsKeys.sendingAll2Kitchen,{arrayIdOrder});
      }
    },
    sendToKitchen: (state, {payload}) => {
      const { arrayIdOrder } = payload; //sgaas
      state.sending2Kitchen = true;
    },
    sendToKitchenCompleted: (state, {payload}) => {
      // changeNotification (kindToast.sendKitchenProcess, notificationsKeys.sendingKitchenOK);
      state.sending2Kitchen = false;
    },
    sendToKitchenFailure: (state, {payload}) => {
      const { errorId } = payload; //sgaas
      // changeNotification (kindToast.sendKitchenProcess, notificationsKeys.sendingKitchenKO);
      state.sending2Kitchen = false;
    }
  },
})

export const { 
  addProduct2Customize,
  removeProductFromCustomizer,
  resetProduct2Customize,
  setCustomizeOption,
  getMyTicket,
  getMyTicketError,
  getMyTicketSuccess,
  ticketReset,
  initSocket,
  setTicketProducts,
  setPending2KitchenValue,
  send2Ticket,
  send2TicketCompleted,
  send2TicketFailure,
  removeFromTicket,
  removeFromTicketCompleted,
  removeFromTicketFailure,
  sendToKitchenAsk,
  sendToKitchen,
  sendToKitchenCompleted,
  sendToKitchenFailure,
 } = ticketSlice.actions


export default ticketSlice.reducer