const grayButton = '/resources/buttons/bot-mini-gris.svg';
const goldButton = '/resources/buttons/bot-mini-oro.svg';
const silverButton = '/resources/buttons/bot-mini-plata.svg';

export const tinyButtons = {
  grayButton,
  goldButton,
  silverButton,
}

export default tinyButtons