import {
  getLocalizedKeyName,
  getLocalizedKeyValue,
} from 'utils/handlers/languageUtils';
/* CUSTOMIZE PRODUCT */
export const getRandomId = () => `${Math.floor(Math.random() * 101)}`;

export const getReadyArrayObjProd = (products2customize) => {
  const arrayObjProd = [];
  products2customize.forEach(editingProduct => {
    const newObjProd = {
      productId: editingProduct.product.productId,
      idPriceOfProductBySize: editingProduct.idPriceOfProductBySize,
      extrasId: '',
      sidesId: '',
      questions: '',
    };
    arrayObjProd.push(newObjProd);
  });
  return arrayObjProd;
}
export const getSettingsForProductStompSlide = () => {
  const settingsForSlide = {
    accessibility: true,
    className: 'products2customizeSlide',
    arrows: false,
    centerMode: false,
    variableWidth:false,
    slidesToShow: 4,
    slidesToScroll: 3,
    swipe: true,
    dots: false,
    speed: 500,
    lazyLoad: false,
    infinite: false,
  };
  return settingsForSlide;
}

export const getSettingsForProductSlide = () => {
  const settingsForSlide = {
    accessibility: true,
    className: 'dishSlider',
    arrows: false,
    centerMode: false,
    variableWidth:false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    dots: false,
    speed: 500,
    lazyLoad: false,
    infinite: false,
  };
  return settingsForSlide;
}


export const removeProductFromCustomizer = (index, products2customize) => {
  const newProduct2Customize = products2customize;
  newProduct2Customize.splice(index, 1);
  return newProduct2Customize;
}


/* PRODUCT QUESTIONS */
export const getSidesOptions = (product, currentLanguage) => {
  let selectedSides = [];
  if(product.sidesId){
    const aux = product.sidesId.split(',');
    selectedSides = [...aux];
  }
  const res = {};
  res.options = [];
  res.selected = [];

  const keyName = getLocalizedKeyName(currentLanguage);
  const { sides } = product;
  if(sides && sides.length > 0){
    sides.forEach(side => {
      const value = side.sidesId;
      const label = side[keyName];
      res.options.push({label,value});
      if(selectedSides.includes(`${value}`)){
        res.selected.push({label,value});
      }
    })
  }
  return res;
}
export const getExtrasOptions = (product, currentLanguage) => {
  let selectedExtras = [];
  if(product.extrasId){
    const aux = product.extrasId.split(',');
    selectedExtras = [...aux];
  }

  const res = {};
  res.options = [];
  res.selected = [];

  const keyName = getLocalizedKeyName(currentLanguage);
  const { extras } = product;
  if(extras && extras.length > 0){
    extras.forEach(extra => {
      const { price } = extra;
      const value = extra.extrasId;
      const label = `${extra[keyName]} ${price}€`;
      res.options.push({label,value});
      if(selectedExtras.includes(`${value}`)){
        res.selected.push({label,value});
      }
    })
  }
  return res;
}

export const getQuestionsAnswersStructuredOptions = (product, currentLanguage) => {

  const res = [];

  const { questionAnswers } = product;
  const answeredQuestions = product.questions;

  const keyValue = getLocalizedKeyValue(currentLanguage);
  if(questionAnswers && questionAnswers.length > 0){
    questionAnswers.forEach(parentQuestion => {
      const answersFromServer = parentQuestion.answers;
      const qansObj = {};
      // ONly take into consideration if we have at least one answers
      if(answersFromServer && answersFromServer.length > 0){
        // question
        qansObj.question={};
        qansObj.question.title = parentQuestion[keyValue];
        qansObj.options = [];
        qansObj.selected = [];
        answersFromServer.forEach(answer => {
          const keyAnswer = answer.idPQAnswer;
          const keyQuestion = parentQuestion.idPPQA;
          qansObj.question.keyQuestion = keyQuestion;
          const label = answer[keyValue];
          qansObj.options.push({label,value: keyAnswer});
          if(answeredQuestions[keyQuestion] && answeredQuestions[keyQuestion] === keyAnswer){
            qansObj.selected.push({label,value: keyAnswer});
          }
        })
        res.push(qansObj);
      }
    })
  }
  return res;
}
export const questionObject2Array = (questionObject) => {
  let res = '';
  const questionObjectKeys = Object.keys(questionObject);
  questionObjectKeys.forEach((key, index) => {
    const answer = questionObject[key];
    res = `${res}${index > 0 ? ',':''}${key}:${answer}`;
  });
  return res;
}
export const validateProductInfo = (product) => {
  const {
    minSides2Choose, maxSides2Choose, sidesId,
    minExtras2Choose, maxExtras2Choose, extrasId,
    questions, questionAnswers,
  } = product;
  let ready2Ticket = true;

  /* SIDES CHECKING */
  const tokenSides = (sidesId) ? sidesId.split(','): [];
  const numberSidesSelected = tokenSides.length;
  const cndSidesBetweenLimits = ((minSides2Choose <= numberSidesSelected) && (maxSides2Choose >= numberSidesSelected));
  const noSides = ((minSides2Choose === maxSides2Choose) && maxSides2Choose === 0);
  if(!cndSidesBetweenLimits && !noSides){
    ready2Ticket = false;
  }
  /* EXTRAS CHECKING */
  const tokenExtras = (extrasId) ? extrasId.split(','): [];
  const numberExtrasSelected = tokenExtras.length;
  const cndExtrasBetweenLimits = ((minExtras2Choose <= numberExtrasSelected) && (maxExtras2Choose >= numberExtrasSelected));
  const noExtras = ((minExtras2Choose === maxExtras2Choose) && maxExtras2Choose === 0);
  if(!cndExtrasBetweenLimits && !noExtras){
    ready2Ticket = false;
  }
  /* QUESTIONS CHECKING */
  const numberQuestionsAnswered = Object.keys(questions).length;
  const numberQuestions2Answer = questionAnswers.length;
  if(numberQuestionsAnswered !== numberQuestions2Answer){
    ready2Ticket = false;
  }

  debugger;
  return ready2Ticket;
}
