import React, { Fragment } from 'react';
import TextDisplayer from '@/components/text-displayer';
import Link from 'next/link';
import { indicators } from '@/resources/indicators';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import { textReferences } from '@/constants/textsReferences';
import { icons } from '@/resources/icons';
import { tinyButtons } from '@/resources/buttons/tiny';
import { CURRENCY } from '@/constants/constants';
import ImageButton from '@/components/image-button';
import { getLocalizedKeyName } from '@/utils/handlers/languageUtils';
import { isACustomizableProduct, transform2CustomizeQuestions } from '@/utils/handlers/productsUtils';
import './styles.scss';

const TicketItem = ({
  buttonOrderText,
  appTexts,
  currentLanguage,
  kind,
  product,
  ticketInfo,
  units,
  onMinus,
  onAdd,
  addProduct2Customize,
  businessId,
  tableId,
  sendToKitchen,
  allowActions,
}) => {
  const customizableItem = isACustomizableProduct(product);
  let linkCustomizeButton;
  if(customizableItem){
    linkCustomizeButton = `/customer/${businessId}/${tableId}/order/customize/product`;
  }
  const productLink =`/customer/${businessId}/${tableId}/${(kind==='food') ? 'eat':kind }/${product.categoryMenuId}/product/${product.productId}`
  const oneTicketInfo = ticketInfo[0];
  const ticketInfoLength = ticketInfo.length;
  const extrasOfThisProduct = product.extras;
  const { idPriceBySize } = oneTicketInfo;
  // extras logic
  const extrasIdList = [];
  let numberofExtras = 0;
  let sumPriceExtras = 0;
  if(customizableItem){
    ticketInfo.forEach(ticketItem => {
      const { extrasId } = ticketItem;
      const tokkenAux = extrasId.split(',');
      tokkenAux.forEach(extraId =>{
        if(extraId){
          extrasIdList.push(extraId);
        }
      });
    });
    numberofExtras = extrasIdList.length;
    // letss to sumup
    extrasIdList.forEach(extraid => {
      const thisExtra = extrasOfThisProduct.find(e => `${e.extrasId}` === extraid);
      sumPriceExtras+=thisExtra.price;
    });
    sumPriceExtras = sumPriceExtras.toFixed(2);
  }
  // extra texts
  let extraTextAux = getTextFromTextList(appTexts, textReferences.NUM_EXTRAS_ADDED, currentLanguage);
  if(numberofExtras <= 1){
    extraTextAux = getTextFromTextList(appTexts, textReferences.NUM_EXTRA_ADDED, currentLanguage);
  }
  extraTextAux = extraTextAux.replace('+', '');
  extraTextAux = extraTextAux.replace('{num}', numberofExtras);
  const extraText = extraTextAux;

  /* extra logic ends */

  const keyName =  getLocalizedKeyName(currentLanguage);
  const name = product[keyName];
  const { prices } = product;
  const currentPrice = prices.find(singlePrice => singlePrice.idPriceOfProductBySize === idPriceBySize);
  const sizeOfThisProduct = currentPrice[keyName];

  const { minus, minusR, plus, plusR, buttonTrash, buttonTrashR, customButton, customButtonR, yellowArrow, plusSymbol } = icons;
  const { grayButton, silverButton } = tinyButtons
  const { priceWarning } = indicators;
  const newPrice = (currentPrice.price * units).toFixed(2);


  const onPlusHandle = () => {
    if(onAdd){
      if(!customizableItem){
        const arrayObjProd = []
        const objProd = {
          productId: product.productId,
          idPriceBySize,
          sidesId:'',
          extrasId:'',
        }
        arrayObjProd.push(objProd);
        onAdd(arrayObjProd);
      }
    }
  }
  const deleteAllItems = () => {
    const idOrderArray = [];
    ticketInfo.forEach(currentTicketInfo => {
      const {  idOrder } = currentTicketInfo;
      idOrderArray.push(idOrder);
    });
    onMinus(idOrderArray);
  }
  const onMinusHandle = () => {
    if(onMinus){
      if(!customizableItem){
        const { idOrder } = ticketInfo[0];
        onMinus([idOrder]);
      } else if(ticketInfoLength > 1){
        addProduct2CustomizeHandler(1);
      } else {
        const { idOrder } = ticketInfo[0];
        onMinus([idOrder])
      }
    }
  }
  const addProduct2CustomizeHandler = (notificationCode=0) => {
    if(addProduct2Customize){
      // mergin data to product
      const arrayObjProd = [];
      ticketInfo.forEach(infoProd => {
        const { questions, idOrder } = infoProd;
        const newProduct = {
          ...product,
          idOrder,
          extrasId:infoProd.extrasId,
          sidesId:infoProd.sidesId,
          questions: transform2CustomizeQuestions(questions),
        };

        arrayObjProd.push(newProduct);
      });
      addProduct2Customize(arrayObjProd,idPriceBySize,notificationCode);
    }
  }

  const handleSendToKitchen = () => {
    if(sendToKitchen){
      const arrayIdOrder = [];
      ticketInfo.forEach(infoProd => {
        const { idOrder, productId } = infoProd;
        if(product.productId === productId){
          arrayIdOrder.push(idOrder);
        }
      });
      sendToKitchen(arrayIdOrder);
    }
  }

  const htmlDisplayer = `<span class="name mimBold">${name}<span> / <span class="size">${sizeOfThisProduct}</span>`;

  return (
    <div className="TicketItem">
      <div className="mainDetails">
        <img className="script" src={icons.greyProductScript} alt="script" />
        <Link to={productLink}>
          <TextDisplayer
            id={`textDisplayer${product.productId}`}
            htmlDisplayer={htmlDisplayer}
            contentClass="mimBold"
            wrapperClass="nameDisplayer"
          />

        </Link>
        <div className="newPrice">
          <span className="mimBold">{newPrice}{CURRENCY}</span>
          <img className="newPriceIndicator" src={priceWarning} alt="totalPrice"/>
        </div>
      </div>
      <div className="opPanel">
        <div className="leftSide">
          <div className="price">
            <span>{currentPrice.price.toFixed(2)}{CURRENCY}</span>
          </div>
          <div className="times"><span className="mimBold">x</span></div>
          <div className="units"><span className="mimBold">{units}</span></div>
        </div>
        {allowActions &&
          <Fragment>
            <ImageButton
              image={minus}
              imageHover={minusR}
              wrapperClass="roundedButton"
              onClick={onMinusHandle}
              link={(customizableItem && ticketInfoLength > 1) ? linkCustomizeButton: null}
            />
            <ImageButton
              image={plus}
              imageHover={plusR}
              onClick={customizableItem ? null : onPlusHandle}
              wrapperClass="roundedButton"
              link={(!customizableItem) ? linkCustomizeButton: productLink}
            />
            {customizableItem &&
            <ImageButton
              image={customButton}
              imageHover={customButtonR}
              wrapperClass="roundedButton customButton"
              onClick={addProduct2CustomizeHandler}
              link={linkCustomizeButton || null}
            />
            }
            <ImageButton
              image={buttonTrash}
              imageHover={buttonTrashR}
              onClick={deleteAllItems}
              wrapperClass="roundedButton bin"
            />
            <ImageButton
              image={grayButton}
              imageHover={silverButton}
              onClick={handleSendToKitchen}
              wrapperClass="orderButton"
            >
              <span className="mimBold mimUpperCase" dangerouslySetInnerHTML={{__html: buttonOrderText}} />
            </ImageButton>
          </Fragment>
        }
        {!allowActions &&
          <div className="noActions">
            <div className="line"></div>
          </div>
        }
      </div>
      {numberofExtras > 0 &&
        <div className="extrasSummary">
          <div className="dataSpace">
            <div className="yellowArrow">
              <img src={yellowArrow} alt="yellowArrow" />
            </div>
            <div className="plusSymbol">
              <img src={plusSymbol} alt="plusSymbol" />
            </div>
            <p dangerouslySetInnerHTML={{__html: extraText}} />
          </div>
          <div className="newPrice">
            <span className="mimBold">{sumPriceExtras}{CURRENCY}</span>
            <img className="newPriceIndicator" src={priceWarning} alt="totalPrice"/>
          </div>
          {oneTicketInfo && !oneTicketInfo.sendToKitchen &&
            <Link
              className="linkOverBox"
              onClick={addProduct2CustomizeHandler}
              to={linkCustomizeButton}
            />
          }
          <p></p>
        </div>
      }

    </div>
  );

};

export default TicketItem;
