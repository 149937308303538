/* indicators */
const orderWarning = '/resources/indicators/aviso-ticket.svg';
const priceWarning = '/resources/indicators/indicador-precio.svg';
const letterZ = '/resources/indicators/z.svg';

export const indicators = {
  orderWarning,
  priceWarning,
  letterZ,
}
