"use client"
import React, { Component } from 'react';
import './TextDisplayer.css';
class TextDisplayer extends Component{
  constructor(props){
    super(props);
    this.state = {
      animated: false,
      noMoving: false,
    }
  }

  componentDidMount = () => {
    const { animated } = this.state;
    const displayerWidth = this.getDisplayerWidth();
    const textWidth = this.getTextWidth();
    if(displayerWidth<textWidth && !animated){
      this.setState({animated: true});
    } else {
      this.setState({ noMoving: true })
    }
  }

  getDisplayerWidth = () => {
    const { id } = this.props;
    const displayer = document.getElementById(id);
    return displayer.clientWidth;
  }

  getTextWidth = () => {
    const { id } = this.props;
    const displayer = document.getElementById(id);
    const item = displayer.firstChild;
    return item.offsetWidth;
  }

  render(){
    const { animated, noMoving } = this.state;
    const { id, htmlDisplayer, wrapperClass, contentClass } = this.props;
    return (
      <div id={id} className={`TextDisplayer${wrapperClass ? ` ${wrapperClass}`:''}`}>
        <div className={`${contentClass}${animated ? ' moving':''} ${noMoving ? 'no-moving' : ''}`} dangerouslySetInnerHTML={{__html:htmlDisplayer}} />
      </div>
    );
  }
}

export default TextDisplayer
