const logoMimGris = '/resources/logos/logo-gris.svg';
const logoMimBlanco = '/resources/logos/logo-blanco.svg';
const logoPieEs = '/resources/logos/pie-texto-castellano.svg';
const logoPieEn = '/resources/logos/pie-texto-ing.svg';
const logoEntrada = '/resources/logos/logoEntrada.svg';
const logoConTituloGrisES = '/resources/logos/logo-gris-titulo-es.svg';
const logoConTituloGrisEN = '/resources/logos/logo-gris-titulo-en.svg';

export const logoSvgs = {
  logoMimGris,
  logoMimBlanco,
  logoPieEs,
  logoPieEn,
  logoEntrada,
  logoConTituloGrisES,
  logoConTituloGrisEN,
};
