import { logoSvgs } from 'resources/logos/';
import { NAV_BACK_EN, NAV_BACK_ES } from 'constants/constants';

export const getNavBack = (currentLanguage) => {
  let navBack;

  switch (currentLanguage) {
    case 'en':
      navBack = NAV_BACK_EN;
      break;
    case 'es':
    default:
      navBack = NAV_BACK_ES;
  }

  return navBack;
}

export const getLocalizedKeyName = (currentLanguage) => {
  let localizedKey;

  switch (currentLanguage) {
    case 'en':
      localizedKey = 'englishName';
      break;
    case 'es':
    default:
      localizedKey = 'spanishName';
  }

  return localizedKey;
}

export const getLocalizedKeyValue = (currentLanguage) => {
  let localizedKey;

  switch (currentLanguage) {
    case 'en':
      localizedKey = 'englishValue';
      break;
    case 'es':
    default:
      localizedKey = 'spanishValue';
  }

  return localizedKey;
}

export const getLocalizedKeyDescription = (currentLanguage) => {
  let localizedKey;

  switch (currentLanguage) {
    case 'en':
      localizedKey = 'englishDescription';
      break;
    case 'es':
    default:
      localizedKey = 'spanishDescription';
  }

  return localizedKey;
}



export const getFooterLogoLocalized = (currentLanguage) => {
  const { logoPieEs, logoPieEn } = logoSvgs;
  let localizedLogo;

  switch (currentLanguage) {
    case 'en':
      localizedLogo = logoPieEn;
      break;
    case 'es':
    default:
      localizedLogo = logoPieEs;
  }

  return localizedLogo;
}

export const getLocalizedKeyTitleNDescription = (currentLanguage) => {
  let localizedKeyTitle;
  let localizedKeyDescription;

  switch (currentLanguage) {
    case 'en':
      localizedKeyTitle = 'englishTitle';
      localizedKeyDescription = 'englishDescription';
      break;
    case 'es':
    default:
      localizedKeyTitle = 'spanishTitle';
      localizedKeyDescription = 'spanishDescription';
  }

  return {
    localizedKeyTitle,
    localizedKeyDescription,
  };
}
