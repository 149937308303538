import React, { Fragment } from 'react';
import { DRINK, FOOD } from '@/constants/constants';
import { textReferences } from '@/constants/textsReferences';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import TitleValance from '@/components/title-valance';
import { valanceTypes } from '@/components/valance';
import TicketItem from '@/components/ticket-item';


export const sortingFunction = (itema, itemb) => {
  const productIdA = parseInt(itema.productId, 10);
  const productIdB = parseInt(itemb.productId, 10);

  if(productIdA === productIdB){
    const idPriceBySizeA = parseInt(itema.idPriceBySize, 10);
    const idPriceBySizeB = parseInt(itemb.idPriceBySize, 10);
    return idPriceBySizeA - idPriceBySizeB;
  }
  return productIdA - productIdB;
}

export const sortTicket = (ticketItems) => {
  const ticket = [];
  const ordered = [];
  // split into two
  ticketItems.forEach(item => {
    const { sent2kitchen } = item;
    if(sent2kitchen){
      ordered.push(item);
    }else{
      ticket.push(item);
    }
  });
  // sorting by productid and size
  ordered.sort((itema, itemb) => {
    const productIdA = parseInt(itema.productId, 10);
    const productIdB = parseInt(itemb.productId, 10);

    if(productIdA === productIdB){
      const idPriceBySizeA = parseInt(itema.idPriceBySize, 10);
      const idPriceBySizeB = parseInt(itemb.idPriceBySize, 10);
      return idPriceBySizeA - idPriceBySizeB;
    }
    return productIdA - productIdB;
  })
  ticket.sort((itema, itemb) => {
    const productIdA = parseInt(itema.productId, 10);
    const productIdB = parseInt(itemb.productId, 10);

    if(productIdA === productIdB){
      const idPriceBySizeA = parseInt(itema.idPriceBySize, 10);
      const idPriceBySizeB = parseInt(itemb.idPriceBySize, 10);
      return idPriceBySizeA - idPriceBySizeB;
    }
    return productIdA - productIdB;
  })
  
  return {ticket, ordered};
}

export const showTicketListOf = (appTexts, menuDrink, menuFood, ticket, products, currentLanguage, businessId, tableId, kind, onMinus, addProduct2Customize, onAdd, sendToKitchen, allowActions=true) =>{
  let menuToUse = menuFood;
  if(kind === DRINK){
    menuToUse = menuDrink;
  }

  let unitsCounter = 0;
  let valanceText = null;
  if (kind === FOOD){
    valanceText = getTextFromTextList(appTexts, textReferences.READY_TO_ORDER_FOOD_TITLE_VALANCE, currentLanguage);
  } else if (kind === DRINK){
    valanceText = getTextFromTextList(appTexts, textReferences.READY_TO_ORDER_DRINK_TITLE_VALANCE, currentLanguage);
  }
  const buttonOrderText = getTextFromTextList(appTexts, textReferences.READY_TO_ORDER_BUTTON_SINGLE, currentLanguage);

  let titleValanceAppeared = 0;
  let ticketInfo = [];
  return ticket.map((item, index) => {
    const nextItem = ticket[(index + 1)];
    const referencedProduct = products.find(p => `${p.productId}` === `${item.productId}`);
    const referencedOnMenu = menuToUse.find(itemMenu => `${itemMenu.categoryMenuId}` === `${referencedProduct.categoryMenuId}`);

    if(referencedOnMenu &&
      (!nextItem ||
        (nextItem && (`${nextItem.productId}` !== `${referencedProduct.productId}`)) ||
        (nextItem && (`${nextItem.idPriceBySize}` !== `${item.idPriceBySize}`))
      )
    ){
      ticketInfo.push(item);
      const ticketInfoGoDown = ticketInfo;
      ticketInfo = [];
      titleValanceAppeared += 1;
      const unitsOfThisProduct = unitsCounter + 1;
      unitsCounter = 0;
      return (
        <Fragment key={`${referencedProduct.productId}${item.idPriceBySize}${index + 0}`}>
          {titleValanceAppeared <= 1 &&
            <TitleValance
              titleText={valanceText}
              valanceType={valanceTypes.UNDERLINE}
              classWrapper="mimBold Section"
            />
          }
          <TicketItem
            buttonOrderText={buttonOrderText}
            appTexts={appTexts}
            businessId={businessId}
            tableId={tableId}
            currentLanguage={currentLanguage}
            kind={kind}
            product={referencedProduct}
            ticketInfo={ticketInfoGoDown}
            units={unitsOfThisProduct}
            onMinus={onMinus}
            addProduct2Customize={addProduct2Customize}
            onAdd={onAdd}
            sendToKitchen={sendToKitchen}
            allowActions={allowActions}
          />
        </Fragment>
      );
    } else if(referencedOnMenu && nextItem && referencedProduct && `${nextItem.productId}` === `${referencedProduct.productId}`){
      unitsCounter+=1;
      ticketInfo.push(item);
    }
    return null;
  })
}
